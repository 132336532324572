import Swiper, { Navigation, Pagination } from 'swiper';
const reviewsSliderInit = () => {
    if (document.querySelector('.js-reviews-slider')) {
        const reviewSlider = new Swiper('.js-reviews-slider', {
            autoHeight: true,
            modules: [Navigation, Pagination],
            pagination: {
                el: '.swiper-pagination',
            },
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
};
export default reviewsSliderInit;
