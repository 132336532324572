import Swiper, { Navigation } from 'swiper';
const blogSliderInit = () => {
    if (document.querySelector('.js-blog-slider')) {
        const blogSlider = new Swiper('.js-blog-slider', {
            modules: [Navigation],
            loop: false,
            spaceBetween: 40,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }
};
export default blogSliderInit;
