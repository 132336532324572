const videoLoading = (element) => {
    var _a, _b;
    let structure = '';
    const videoContainer = element;
    if (!videoContainer)
        return false;
    if ((_a = videoContainer.dataset) === null || _a === void 0 ? void 0 : _a.youtube) {
        structure = `<iframe src="${videoContainer.dataset.youtube}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>`;
        videoContainer.innerHTML = structure;
    }
    else if ((_b = videoContainer.dataset) === null || _b === void 0 ? void 0 : _b.video) {
        structure = `<video controls autoplay="false" src="${videoContainer.dataset.video}"></video>`;
        videoContainer.innerHTML = structure;
    }
    return true;
};
export default videoLoading;
