const navigation = () => {
    const activeElements = document.querySelectorAll('.js-add-active');
    const burger = document.querySelector('.js-burger');
    burger === null || burger === void 0 ? void 0 : burger.addEventListener('click', () => {
        activeElements === null || activeElements === void 0 ? void 0 : activeElements.forEach((item) => {
            item.classList.toggle('active');
        });
        document.body.classList.toggle('no-scroll');
    });
};
export default navigation;
