import '../sass/frontend.scss';
import AOS from 'aos';
import Popup from './parts/popup-window';
import menuNavigation from './components/navigation';
import blogSliderInit from './components/blog-slider';
import reviewsSliderInit from './components/reviews-slider';
import videoLoading from './components/video-loading';
import faqSwitcher from './components/faq';
AOS.init();
const popupInstance = new Popup();
popupInstance.init();
const onLoad = () => {
    menuNavigation();
    blogSliderInit();
    reviewsSliderInit();
    document.body.addEventListener('click', (e) => {
        var _a;
        const TARGET = e.target;
        const ROLE = (_a = TARGET.dataset) === null || _a === void 0 ? void 0 : _a.role;
        switch (ROLE) {
            case 'video-loader':
                videoLoading(TARGET);
                break;
            case 'faq-switcher':
                faqSwitcher(TARGET);
                break;
            default:
        }
    });
};
window.document.addEventListener('DOMContentLoaded', onLoad);
